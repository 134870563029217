import React from 'react';

import Layout from '../components/Layout/index.js';
import {Helmet} from 'react-helmet';

const NotFoundPage = () => (
  <Layout>
    <Helmet
      meta={[
        {
          name: 'robots',
          content:
            'noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1',
        },
      ]}
    ></Helmet>
    <h1>404: Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
